<template>
    <div class="row justify-content-center">
        <div class="col-md-5">
            <h3 class="text-center">Add Custom Field</h3>
            <form @submit.prevent="onFormSubmit">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="newcf.name" required>
                </div>

                <div class="form-group">
                    <label>Type</label>
                    <br>
                    <select v-model="newcf.type" required>
                        <option value="text">Short Text</option>
                        <option value="checkbox">Checkbox</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Category</label>
                    <br>
                    <select v-model="newcf.category" required>
                        <option value="Service">Service</option>
                        <option value="Cemetery">Cemetery</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                <div class="form-group">
                    <button class="btn btn-primary btn-block">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                cf: [{
                }],
                newcf: {
                    name: '',
                    type: '',
                    category: '',
                },
                currentTenant: "",
                FormUpdated: false,
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.cf.deleted = false;
            this.updateservices();
        },
        methods: {
            updateservices() {
                let dbRef = db.collection('tenants').doc(this.currentTenant);
            dbRef.get().then((doc) => {
                this.cf = doc.data().fields;
                console.log(this.cf)
            }).catch((error) => {
                console.log(error)
            })
            this.FormUpdated = true;
            },
            onFormSubmit(event) {
                this.cf.push(this.newcf);
                event.preventDefault()
                db.collection('tenants').doc(this.currentTenant).update({fields: this.cf}).then(() => {
                    console.log("Service Location successfully updated!");
                    this.$router.push('/cfl')
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }
</script>